import dynamicSiteConfig from '../../../../src/utils/dynamic/dynamicSiteConfig';

// This is where you dynamically change information for an identical site with a different domain.
// Don't forget to import any images you may need

/**
 *
 * @param {*} config - config object. Use this to alter any config options you need to
 * @param {*} setDocumentTitle - function. Needed to implicitly change the document title
 * @param {*} query - params object.
 */

const customConfig = config => {
  /**
   * updates main config from dynamicSites config
   * @param  {object} config
   */
  dynamicSiteConfig(config);

  const url = document.URL;
  const header = document.getElementById('header');

  if (url.includes('nerdywallethomeinsurance.com')) {
    if (header) {
      header.classList.add('nerdyWallet');
    }
  }
  if (url.includes('nerdywalletinsured.com')) {
    if (header) {
      header.classList.add('nerdyWallet');
    }
  }
  if (url.includes('trustedhomesurvey.com')) {
    const mainApp = document.getElementById('main-app');
    mainApp.classList.add('ths');
  }
};

export default customConfig;
